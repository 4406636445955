<template>
	<div class="index" style="display: flex;justify-content: center;">

		<div style="padding: 20px;width: 100%;max-width: 1000px;">

			<div style="margin: 10px 0;" class="re_from">
				<div>
					<label class="re_laber_input" v-if="robot_id>0">{{$t('robot_edit.robot_1')}} {{robot_id}}</label>
					
				</div>
				<div>
					<label class="re_laber_input">{{$t('robot_edit.robot_2')}}</label>
					<div><input type="text" placeholder="Enter address" class="re_input" v-model="user_key" /></div>
				</div>
				<div>
					<label class="re_laber_input">{{$t('robot_edit.robot_3')}}</label>
					<div><input type="text" placeholder="Enter address" class="re_input" v-model="user_secret" /></div>
				</div>
				
				<div>
					<label class="re_laber_input">{{$t('robot_edit.robot_4')}}</label>
					<div><input type="text" placeholder="Enter address" class="re_input" v-model="user_key2" /></div>
				</div>
				<div>
					<label class="re_laber_input">{{$t('robot_edit.robot_5')}}</label>
					<div><input type="text" placeholder="Enter address" class="re_input" v-model="user_secret2" /></div>
				</div>
				<div>
					<label class="re_laber_input">{{$t('robot_edit.robot_6')}}</label>
					<div>
						<!-- <input type="text" placeholder="Enter Secret" class="re_input" v-model="currency_type" /> -->
						<el-select v-model="value"  class="re_input" @change="changeselect(value)">
						    <el-option
						      v-for="item in options"
						      :key="item.value"
						      :label="item.label"
						      :value="item.value">
						    </el-option>
						  </el-select>
						  
					</div>
				</div>
				<div>
					<label class="re_laber_input">{{$t('robot_edit.robot_7')}}</label>
					<div><input type="number" placeholder="Enter address" class="re_input" v-model="user_usdt" /></div>
				</div>
				<div>
					<label class="re_laber_input">{{$t('robot_edit.robot_8')}}</label>
					<div><input type="number" placeholder="Enter address" class="re_input" v-model="user_max_loss_usdt" /></div>
				</div>
				<div style="display: flex;justify-content: center;">
					<button class="btn_blue_class" style="margin: 20px 0;max-width: 500px;" @click="robotedit()"><span>{{$t('robot_edit.robot_9')}}</span></button>
				</div>
			</div>
			
			
			
			
			
		
		
		</div>

	</div>
</template>

<script>
	import request from '@/utils/request'
	export default {
		name: 'robotedit',
		data() {
			return {
				robot_id:0,
				RobotList:[],
				user_key:'',
				user_key2:'',
				user_secret:'',
				user_secret2:'',
				user_usdt:'',
				user_max_loss_usdt:'',
				Robot:{},
				currency_type:0,//1 usdt  2 buds 货币类型
				options: [{
				          value: '1',
				          label: 'USDT'
				        }, {
				          value: '2',
				          label: 'BUDS'
				}],
				value:'',
			};
		},
		activated() {
		    //console.log('我这个页面显示就会执行');
			let _this = this;
			//刷新
			// if(_this.$route.params.refresh){
			// 	//_this.$router.go(0)
			// 	location.reload();
			// }
			let id = _this.$route.params.id > 0 ? _this.$route.params.id : 0 ;
			_this.robot_id = id;
			
			console.log(id)
			
			
		},
		mounted() {
			let _this = this;
			let id = _this.$route.params.id > 0 ? _this.$route.params.id : 0 ;
			_this.robot_id = id;
			
			this.local_data();
			
			

			window.addEventListener("resize", () => this.$refs?.ref_echart_index?.resize());
			
		},

		methods: {
			//下拉列表
			changeselect(value){
				let _this = this;
				let token = window.sessionStorage.getItem('token');
				//console.log(value)
				_this.currency_type = value;
			},
			local_data(){
				let _this = this;
				let token = window.sessionStorage.getItem('token');
				if (window.sessionStorage.getItem('token')) {
					if(window.sessionStorage.getItem('token') == 'undefined'){
						_this.$router.replace({ name: 'Login', params: { refresh: true }})
					}else{

					}
				}else{
					_this.$router.replace({ name: 'Login', params: { refresh: true }})
				}
				console.log(_this.robot_id)
				if(_this.robot_id == 0 ){
					let id = _this.$route.params.id > 0 ? _this.$route.params.id : 0 ;
					if(id == 0){
						_this.$router.push('/index');
					}
					
				}
				if(_this.robot_id>0){
					_this.getRobot(_this.robot_id)
				}else{
					_this.$message({
					    message: _this.$t('notify.error'),
					    type: 'warning'
					});
				}
			},
			robotadd(){
				let _this = this;
				let token = window.sessionStorage.getItem('token');
				let user_key = _this.user_key
				let user_key2 = _this.user_key2
				let user_secret = _this.user_secret
				let user_secret2 = _this.user_secret2
				let user_usdt = _this.user_usdt
				let user_max_loss_usdt = _this.user_max_loss_usdt
				request.post('robot/add', {
					auth: token,
					key:user_key,
					key1:user_key2,
					secret:user_secret,
					secret1:user_secret2,
					usdt:user_usdt,
					max_loss_usdt:user_max_loss_usdt,
					type:1,
					
				}).then(res => {
					console.log(res)
					//结果
					if(res.data.errno ==0){
						_this.$message({
						    message: 'success',
						    type: 'success'
						});
					}else{
						
						_this.$message({
						    message: _this.$t('disc.Please log in'),
						    type: 'warning'
						});
					}
				});
			},
			robotedit(){
				let _this = this;
				let token = window.sessionStorage.getItem('token');
				let user_key = _this.user_key
				let user_key2 = _this.user_key2
				let user_secret = _this.user_secret
				let user_secret2 = _this.user_secret2
				let user_usdt = _this.user_usdt
				let user_max_loss_usdt = _this.user_max_loss_usdt;
				let currency_type = _this.currency_type;
				let robot_id = _this.robot_id;
				if(user_key == '' || user_key2 == ''){
					_this.$message({
					    message: '请输入key',
					    type: 'warning'
					});
					return;
				}
				if(user_secret == '' || user_secret2 == ''){
					_this.$message({
					    message: '请输入secret',
					    type: 'warning'
					});
					return;
				}
				if(user_usdt == '' || user_max_loss_usdt == ''){
					_this.$message({
					    message: '请设置usdt',
					    type: 'warning'
					});
					return;
				}
				if(currency_type == 0){
					_this.$message({
					    message: '请选择货币类型',
					    type: 'warning'
					});
					return;
				}
				request.post('robot/edit', {
					auth: token,
					key:user_key,
					key1:user_key2,
					secret:user_secret,
					secret1:user_secret2,
					usdt:user_usdt,
					max_loss_usdt:user_max_loss_usdt,
					type:1,
					id:robot_id,
					currency_type:currency_type,
					
				}).then(res => {
					console.log(res)
					//结果
					if(res.data.errno ==0){
						_this.$message({
						    message: 'success',
						    type: 'success'
						});
						_this.getRobot(robot_id)
					}else{
						
						_this.$message({
						    message: _this.$t('disc.Please log in'),
						    type: 'warning'
						});
					}
				});
			},
			
			getRobot(id){
				let _this = this;
				let token = window.sessionStorage.getItem('token');
				
				request.post('robot/getRobot', {
					auth: token,
					id:id,
				}).then(res => {
					//结果
					if(res.data.errno ==0){
						_this.Robot = res.data.data;
						_this.user_key = res.data.data.key;
						_this.user_key2 = res.data.data.key1;
						_this.user_secret = res.data.data.secret;
						_this.user_secret2 = res.data.data.secret1;
						_this.user_usdt = res.data.data.usdt;
						_this.user_max_loss_usdt = res.data.data.max_loss_usdt;
						_this.robot_id = res.data.data.id;
						_this.currency_type = res.data.data.currency_type;
						
						if(res.data.data.currency_type == 0){
							_this.value = '';
						}else if(res.data.data.currency_type == 1){
							_this.value = 'USDT';
						}else if(res.data.data.currency_type == 2){
							_this.value = 'BUDS';
						}else{
							_this.value = '';
						}
						
					}else{
						
						_this.$message({
						    message: _this.$t('disc.Please log in'),
						    type: 'warning'
						});
					}
				});
			},
			
			
			
			
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	.el-input__inner{
		background-color: #292d36;
		border: 1px solid #292d36;
		border: none;
		color:#fff;
	}
	.el-select .el-input.is-focus .el-input__inner {
	    border-color: #C0C4CC;
	}
	.el-select .el-input__inner:focus {
	    border-color: #C0C4CC;
		border: none;
	}
	.btn_blue_class {
		-webkit-box-align: center;
		align-items: center;
		border: 0px;
		border-radius: 16px;
		box-shadow: rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset;
		cursor: pointer;
		display: inline-flex;
		font-family: inherit;
		font-size: 16px;
		font-weight: 600;
		-webkit-box-pack: center;
		justify-content: center;
		letter-spacing: 0.03em;
		line-height: 1;
		opacity: 1;
		outline: 0px;
		transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
		height: 52px;
		padding: 0px 16px;
		background-color: rgb(31, 199, 212);
		color: white;
		width: 100%;
		
	}

	.re_from {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 10px;
		margin-bottom: 10px
	}

	.re_laber_input {
		font-size: 21px;
		font-weight: 500;
		color: #fff;
		margin-bottom: 10px;
		display: block
	}

	.re_input {
		height: 56px;
		line-height: 56px;
		width: 100%;
		background-color: #292d36;
		border: 1px solid #596775;
		border-radius: 10px;
		color: #fff;
		font-size: 16px;
		font-weight: 500;
		padding: 0 10px;
		word-wrap:break-word;
		word-break:normal; 
	}
	.re_input2 {
		height: 56px;
		line-height: 56px;
		width: 100%;
		background-color: #292d36;
		/* border-bottom: 1px solid #596775; */
		border-radius: 10px;
		color: #fff;
		font-size: 16px;
		font-weight: 500;
		padding: 0 10px;
		word-wrap:break-word;
		word-break:normal; 

	}
</style>
